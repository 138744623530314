
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { Localization } from '@/interfaces/models/Localization';
import VRichText from '@/components/shared/form/VRichText.vue';

interface Language {
  key: string;
  value: string | TranslateResult;
}

interface LanguageItem {
  lang: string;
  value: string;
}

@Component({
  components: { VRichText },
})
export default class VLanguageField extends Vue {
  @Prop({ type: String, required: true }) public name!: string;
  @Prop({ type: String, required: true }) public label!: string;
  @Prop({ type: Boolean, default: false }) public required!: boolean;
  @Prop({ required: true }) public value!: Localization;
  @Prop({ type: Array, default: () => [] }) public defaultItems!: string[];
  @Prop({ type: String, default: 'de' }) public defaultLocale!: string;
  @Prop({ type: Array, default: () => [] }) public errors: any;
  @Prop({ type: Boolean, default: false }) public useTextarea!: boolean;
  @Prop({ type: Boolean, default: false }) public useRichText!: boolean;

  public selectedLanguage: string = '';
  public forbiddenFields: string[] = ['createdAt', 'updatedAt', 'deletedAt', '_id'];
  public languages: Language[] = [];
  public items: LanguageItem[] = [];
  public itemsLoaded: boolean = false;

  @Watch('value')
  public onValueChange(val: LanguageItem, oldVal: LanguageItem) {
    // @ts-ignore
    if (!oldVal) {
      return;
    }
    if (Object.values(val).join(',') !== Object.values(oldVal).join(',')) {
      this.init();
    }
  }

  public updateItemValue(item: LanguageItem, value: string) {
    item.value = value;
    return this.onChange();
  }

  get orgLanguages() {
    return [
      { value: this.$t('languages.en'), key: 'en' },
      { value: this.$t('languages.fr'), key: 'fr' },
      { value: this.$t('languages.es'), key: 'es' },
      { value: this.$t('languages.nl'), key: 'nl' },
      { value: this.$t('languages.it'), key: 'it' },
      { value: this.$t('languages.pl'), key: 'pl' },
      { value: this.$t('languages.zh'), key: 'zh' },
      { value: this.$t('languages.tr'), key: 'tr' },
      { value: this.$t('languages.ru'), key: 'ru' },
      { value: this.$t('languages.da'), key: 'da' },
      { value: this.$t('languages.ja'), key: 'ja' },
      { value: this.$t('languages.is'), key: 'is' },
      { value: this.$t('languages.pt'), key: 'pt' },
      { value: this.$t('languages.cz'), key: 'cz' },
    ];
  }

  public created() {
    this.init();
  }

  public init() {
    this.languages = [];
    this.items = [];
    this.languages.push(...this.orgLanguages);
    this.items.push({ lang: this.defaultLocale, value: '' });
    this.$nextTick(() => {
      if (this.value) {
        this.initDefault();
      }
    });
  }

  public addItem() {
    if (
      this.selectedLanguage !== '' &&
      this.items.filter((item) => {
        return item.lang === this.selectedLanguage;
      }).length === 0
    ) {
      // @ts-ignore
      this.items.push({ lang: this.selectedLanguage, value: '' });

      this.languages = this.languages.filter((item) => {
        return item.key !== this.selectedLanguage;
      });
      this.$emit('input', this.mapInput());
    }
  }

  public removeItem(index: number) {
    if (this.items.length > 1) {
      const language = this.orgLanguages.filter((item) => {
        return item.key === this.items[index].lang;
      });
      this.languages.push(...language);
      this.items.splice(index, 1);
      this.$emit('input', this.mapInput());
    }
  }

  public mapInput(): Localization {
    const values: Localization = {} as Localization;
    for (const item of this.items) {
      // @ts-ignore
      values[item.lang] = item.value;
    }

    return values;
  }

  @Emit('input')
  public onChange() {
    return this.mapInput();
  }

  public initDefault() {
    const keys: string[] = Object.keys(this.value);
    let edit: boolean = false;
    const existingItems: LanguageItem[] = [];
    for (const key of keys) {
      // @ts-ignore
      const val: string = this.value[key];
      if (this.forbiddenFields.indexOf(key) === -1) {
        edit = true;
        if (keys.indexOf('_id') > -1) {
          if (val !== '' || key === 'de') {
            existingItems.push({ lang: key, value: val });
          } else {
            continue;
          }
        } else {
          existingItems.push({ lang: key, value: val });
        }
        const languages: Language[] = this.languages.filter((item) => {
          return item.key !== key;
        });
        this.languages = languages;
      }
    }
    if (edit) {
      this.items = [];
    }
    this.items.push(...existingItems);
    this.itemsLoaded = true;
  }
}
