
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
  // @ts-ignore
  Table,
  // @ts-ignore
  TableCell,
  // @ts-ignore
  TableHeader,
  // @ts-ignore
  TableRow,
} from 'tiptap-vuetify';

@Component({
  components: { TiptapVuetify },
})
export default class VRichText extends Vue {
  @Prop({ type: String }) public value!: string;
  @Prop({ type: String, required: false }) public name!: string;
  @Prop({ type: String, required: false }) public label!: string;
  @Prop({ type: Boolean, required: false }) public open!: boolean;
  @Prop({ type: Array, required: false }) public errorMessages!: string[];

  public isOpen: number = -1;

  protected extensions: any[] = [
    History,
    Blockquote,
    Link,
    Underline,
    Strike,
    Italic,
    ListItem,
    BulletList,
    OrderedList,
    [
      Heading,
      {
        options: {
          levels: [1, 2, 3],
        },
      },
    ],
    Bold,
    Code,
    HorizontalRule,
    Paragraph,
    HardBreak,
    Table,
    TableCell,
    TableHeader,
    TableRow,
  ];

  public $refs!: {
    tiptapVuetify: InstanceType<typeof TiptapVuetify> & {
      editor: any;
    };
  };

  protected content: string = '';

  public mounted(): void {
    if (this.value) {
      this.content = this.value;
    }
  }

  public isEmpty() {
    return !!this.$refs.tiptapVuetify.editor.state.doc.textContent.trim().length;
  }

  @Watch('value')
  public onValueChange() {
    if (this.value) {
      this.content = this.value;
    }
  }

  public openPanel() {
    this.isOpen = 0;
  }

  @Watch('open', { immediate: true })
  public onOpenChange(val: boolean) {
    this.isOpen = val ? 0 : -1;
  }

  @Emit('input')
  public onChange() {
    return this.isEmpty() ? this.content : '';
  }
}
