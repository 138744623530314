
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import moment, { Moment } from 'moment';

@Component
export default class VDateTimePicker extends Vue {
  @Prop({ type: Number, default: 340 }) public width!: number;
  @Prop({ type: String, default: '' }) public label!: string;
  @Prop({ type: Boolean }) public disabled!: boolean;
  @Prop({ type: String, default: '' }) public value!: string;

  public activeTab: number = 0;
  public date: string = '';
  public time: string = '';
  public isVisible: boolean = false;

  get dateSelected() {
    return !this.date || this.date.length === 0;
  }

  get formattedDateTime() {
    if (this.date && this.time) {
      return `${this.date} ${this.time}`;
    }

    return '';
  }

  public mounted() {
    this.init();
  }

  public init() {
    if (this.value) {
      const dateTime: Moment = moment(this.value);
      this.date = dateTime.format('YYYY-MM-DD');
      this.time = dateTime.format('HH:mm');
    }
  }

  public cancel() {
    this.isVisible = false;
  }

  public showTimePicker() {
    this.activeTab = 1;
  }

  @Emit('input')
  public save() {
    this.isVisible = false;
    return moment(this.formattedDateTime).toISOString();
  }

  @Emit('input')
  public clear() {
    this.isVisible = false;
    return '';
  }

  @Watch('value')
  public onValueChange() {
    this.init();
  }
}
